import React, { useState, useEffect } from 'react'
import { TabPanel } from 'react-tabs'
import { Button } from 'reactstrap'
import axios from 'axios';
import PropTypes from 'prop-types'
import WishlistProduct from './WishlistProduct'
import ListsModalLink from './Features/ListsModal/ListsModalLink'
import PrintModalLink from './Features/PrintModal/PrintModalLink'
import EditListModalLink from './Features/EditList/EditListModalLink'
import miniCartClient from '../util/miniCartClient'
import { translateResourceString } from '../util/translationUtility';

const WishlistsTabPanels = ({
    Lists = [],
    ToggleEditListModal,
    ToggleListsModal,
    UpdateLists,
    ToggleCopyModal,
    ProductCommand,
    TogglePrintModal,
    PricesAreHidden = false,
    AddToCartEndpoint,
    AddListToCartEndpoint,
    UpdateProductEndpoint,
    RemoveItemEndpoint
}) => {
    const [itemsUpdating, setItemsUpdating] = useState([]);
    const [quantityUpdateRequests, setQuantityUpdateRequests] = useState([]);

    useEffect(() => {
        handleUpdateQtyRequest();
    });

    const removeProduct = (sku, listIndex) => {
        if (Lists[listIndex].Items && Lists[listIndex].Items.length > 0) {
            if (window.confirm('Are you sure you wish to delete this product?')) {
                Remove(RemoveItemEndpoint, sku, listIndex)
            }
        }
    }

    const updateProducts = (sku, listIndex) => {
        let newItems = Lists[listIndex].Items.filter(product => product.SKU !== sku)
        let newList = [...Lists.slice(0, listIndex), { Name: Lists[listIndex].Name, Items: newItems }, ...Lists.slice(listIndex + 1, Lists.length)]
        UpdateLists(newList)
    }

    const Remove = (endpoint, sku, listIndex) => {
        axios.post(endpoint, {
            listName: Lists[listIndex].Name,
            sku: sku
        })
            .then(function (response) {
                switch (response.data) {
                    case 'OK':
                        updateProducts(sku, listIndex)
                        break
                    case 'BadRequest':
                        alert('Invalid parameters')
                        break
                    case 'InternalServerError':
                        alert('Something went wrong')
                        break
                    default:
                        console.error('Unhandled Response.')
                        break
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const AddToCart = (sku, quantity) => {
        axios.post(AddToCartEndpoint, {
            code: sku,
            quantity: quantity
        },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                }
            })
            .then(function (response) {
                //console.log(response.data);
                if (response.status === 200) {
                    if (response.data && response.data.hasError) {
                        const message = response.data["errorMessage"];
                        if (message)
                            alert(message)
                        else
                            alert('Unable to add product to cart')
                    } else {
                        miniCartClient.refreshAndShow();
                    }
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const setItemUpdating = (itemSku, isUpdating) => {
        if (isUpdating && !itemsUpdating.includes(itemSku)) {
            setItemsUpdating([...itemsUpdating, itemSku]);
        }
        if (!isUpdating && itemsUpdating.includes(itemSku)) {
            setItemsUpdating(itemsUpdating.filter(x => { return x !== itemSku; }));
        }
    }

    const UpdateQty = (sku, listName, quantity) => {
        setItemUpdating(sku, true);
        setQuantityUpdateRequests([...quantityUpdateRequests, { sku, listName, quantity: parseInt(quantity) }]);
    }

    const handleUpdateQtyRequest = async () => {
        if (quantityUpdateRequests.length > 0) {
            let request = quantityUpdateRequests.shift();

            let response = await axios.post(UpdateProductEndpoint, {
                listName: request.listName,
                sku: request.sku,
                newQuantity: request.quantity
            });

            handleUpdateQtyResponse(response, request.sku, request.listName, request.quantity);
        }
    }

    const handleUpdateQtyResponse = (response, sku, listName, quantity) => {
        switch (response.data) {
            case 'OK':
                updateProductQty(listName, sku, quantity)
                break
            case 'BadRequest':
                alert('Invalid parameters')
                break
            case 'InternalServerError':
                alert('Something went wrong')
                        break
                    default:
                        console.error('Unhandled Response.')
                break
        }

        setItemUpdating(sku, false);
    }

    const updateProductQty = (listName, sku, quantity) => {
        let listIndex = Lists.findIndex(x => x.Name === listName)
        let productIndex = Lists[listIndex].Items.findIndex(x => x.SKU === sku)
        let product = Lists[listIndex].Items[productIndex]
        product.Quantity = quantity
        const replace = Number(product.Price.replace(/[^0-9.]/g, ''));
        if (!isNaN(replace)) {
            product.TotalPrice = (replace * quantity).toLocaleString('en-us', { style: 'currency', currency: 'USD' })
        } else {
            product.TotalPrice = "Nan";
        }

        let newItems = [
            ...Lists[listIndex].Items.slice(0, productIndex),
            product,
            ...Lists[listIndex].Items.slice(productIndex + 1, Lists[listIndex].Items.length)
        ]

        let newList = [
            ...Lists.slice(0, listIndex),
            {
                Name: Lists[listIndex].Name,
                Items: newItems
            },
            ...Lists.slice(listIndex + 1, Lists.length)
        ]

        UpdateLists(newList)
    }

    const addListToCart = (listName) => {
        axios.post(AddListToCartEndpoint, { listName: listName },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                }
            })
            .then(function (response) {
                //console.log(response.data);
                if (response.status === 200) {
                    if (response.data && response.data.hasError) {
                        const message = response.data["errorMessages"];
                        if (message)
                            alert(message)
                        else
                            alert('Unable to add list to cart')
                    } else {
                        miniCartClient.refreshAndShow();
                    }
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    return (
        <div className="col-12 col-md-8 list-content">
            {
                Lists.map((list, i) =>
                    <TabPanel key={list.Name}>
                        <div className="col-flex">
                            <h3 className="headline headline-sm">{list.Name}</h3>
                            <div className="tools">
                                <ListsModalLink
                                    AdditionalClasses="d-md-none"
                                    Color="alt"
                                    Size="xs"
                                    LinkText={translateResourceString("/Wishlists/ViewAndAddListsButton")}
                                    ToggleListsModal={ToggleListsModal}
                                />
                                <EditListModalLink
                                    AdditionalClasses="d-md-none"
                                    Color="alt"
                                    Size="xs"
                                    ToggleEditListModal={ToggleEditListModal}
                                    Name={list.Name} />
                                <PrintModalLink TogglePrintModal={TogglePrintModal} />
                                <Button color="secondary" size="xs" onClick={() => addListToCart(list.Name)}>{translateResourceString("/Wishlists/AddListToCartButton")}</Button>
                            </div>
                        </div>
                        <ul className="list-group wishlist-items mt-2">
                            <li>
                                {
                                    list.Items.map((item, j) =>
                                        <WishlistProduct
                                            key={item.SKU}
                                            {...item}
                                            ListIndex={i}
                                            RemoveProduct={removeProduct}
                                            ToggleCopyModal={ToggleCopyModal}
                                            ProductCommand={ProductCommand}
                                            ListId={list.Name}
                                            PricesAreHidden={PricesAreHidden}
                                            UpdateQty={UpdateQty}
                                            AddToCart={AddToCart}
                                            isUpdating={itemsUpdating.includes(item.SKU)}
                                        />
                                    )
                                }
                            </li>
                        </ul>
                    </TabPanel>
                )
            }
        </div>
    );
};

WishlistsTabPanels.propType = {
    Lists: PropTypes.arrayOf(
        PropTypes.shape({
            Name: PropTypes.string,
            Items: PropTypes.array
        })
    ),
    ToggleEditListModal: PropTypes.func.isRequired,
    ToggleListsModal: PropTypes.func.isRequired,
    UpdateLists: PropTypes.func.isRequired,
    ToggleCopyModal: PropTypes.func.isRequired,
    ProductCommand: PropTypes.shape({
        ListId: PropTypes.string.isRequired,
        ProductId: PropTypes.number.isRequired,
        RemoveAfter: PropTypes.bool.isRequired
    }).isRequired,
    TogglePrintModal: PropTypes.func.isRequired,
    PricesAreHidden: PropTypes.bool,
    AddToCartEndpoint: PropTypes.string.isRequired,
    AddListToCartEndpoint: PropTypes.string.isRequired,
    UpdateProductEndpoint: PropTypes.string.isRequired,
    RemoveItemEndpoint: PropTypes.string.isRequired
};

export default WishlistsTabPanels;
