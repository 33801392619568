import React from 'react';
import { AddressShape } from './propTypes';
import { translateResourceString } from '../../../util/translationUtility';

const spanStyleCompact = {
    marginLeft: 5,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0
}

const spanStyle = {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    display: "block"
}

const addressStyleCompact = {
    margin: 0,
    display: 'inline-block'
}

const addressStyle = {
    margin: 0
}

export default function AddressDisplay(props) {
  if (!props.address) {
    return (<div className="address missing">No address provided.</div>);
  }
  const min          = props.compact;
  const nameEmph     = props.compact ? ' strong' : '';
  const addr         = props.address;
  const cityStateZip = `${addr.City}, ${addr.State}  ${addr.PostalCode}`;
  const s = props.compact ? spanStyleCompact : spanStyle;
  const si = Object.assign({}, s, { marginLeft: 0 });
  const sj = Object.assign({}, si, { fontWeight: 'bold' });
  const a = props.compact ? addressStyleCompact : addressStyle;
  return (
    <div className={`address ${min ? 'address-compact' : ''}`} style={a}>
      <div style={min ? { } : {}}>
        <span className={`name ${nameEmph}`}   style={sj}>{addr.Name}</span>
        <span className="line1"                style={s}>{addr.AddressLine1}</span>
        <span hidden={min || !addr.AddressLine2}>
          <span className="line2" hidden={min} style={s}>{addr.AddressLine2}</span>
        </span>
      </div>
      <div style={min ? { } : {}}>
        <span className="cityStateZip"         style={si}>{cityStateZip     }</span>
        <span className="country" hidden={min} style={s}>{addr.CountryName}</span>
        <span hidden={min || !addr.Phone}>
          <span className="phone" hidden={min} style={s}>{addr.Phone}</span>
        </span>
      </div>
    </div>
  );
}

AddressDisplay.propTypes = {
    address: AddressShape.propTypes
}