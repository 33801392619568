import React from 'react'
import PropTypes from 'prop-types'
import ModalTab from './ModalTab'
import NewListInput from '../wishlists/Components/NewListInput'
import ProductItem from '../wishlists/Components/ProductItem'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import axios from 'axios';
import { translateResourceString } from '../util/translationUtility'

const AddToModal = ({
    Lists = [],
    UpdateLists,
    Name,
    SKU,
    ImageUrl,
    ModalIsOpen,
    ToggleModal,
    AddItemEndpoint,
    CreateListEndpoint,
    SetLinkClass,
    SetSrText,
    SetLinkText,
    UserIsAuthorized,
    LoginLink
}) => {

    const spinnerClass = 'fa fa-circle-notch fa-spin';
    const successClass = 'fa fa-check-circle';
    const errorClass = 'fa fa-times-circle error';
    const addToListText = translateResourceString("/Wishlists/AddToModal/AddToListButton");
    const addedToListText = translateResourceString("/Wishlists/AddToModal/AddedToListMessage");
    const retryMessage = translateResourceString("/Wishlists/AddToModal/AddedToListMessage");

    const addProduct = (targetListName) => {

        let element = document.getElementsByClassName('qty-option')
        let quantity = element.length ? element[0].value : 1
        quantity = Number.isInteger(+quantity) ? quantity : 1

        SetLinkClass(spinnerClass)

        const requestData = {
            listName: targetListName,
            skus: { [SKU]: quantity }
        };
        axios.post(AddItemEndpoint, requestData)
            .then(function (response) {
                switch (response.data) {
                    case 'OK':
                        ToggleModal(false)
                        SetLinkClass(successClass)
                        SetSrText(addedToListText)
                        SetLinkText(addedToListText)
                        break
                    case 'BadRequest':
                        SetLinkClass(errorClass)
                        SetLinkText(addToListText)
                        alert(retryMessage)
                        console.error("Invalid parameters: " + JSON.stringify(requestData) + ". Got response: " + response.data)
                        break
                    case 'InternalServerError':
                        SetLinkClass(errorClass)
                        SetLinkText(addToListText)
                        alert(retryMessage)
                        console.error(response.data)
                        break
                    case 'Unauthorized':
                        SetLinkClass(errorClass)
                        SetLinkText(addToListText)
                        alert(translateResourceString("/Wishlists/AddToModal/PleaseLoginMessage"))
                        break
                    default:
                        console.error('Unhandled Response.')
                        break
                }
            })
            .catch(function (error) {
                SetLinkClass(errorClass)
                SetLinkText(addToListText)
                console.log(error)
            });
    }

    const modalBody = UserIsAuthorized ? (
        <ModalBody className="list-sidebar pb-0" >
            <div className="container-fluid" >
                <div className="row" >

                    <div className="col-lg-4 d-none d-lg-block list-modal-product" >
                        <ProductItem
                            Name={Name}
                            ImageUrl={ImageUrl} />
                    </div>

                    <div className="col-lg-8 p-0 d-flex flex-column">
                        <ul className="p-0 list-modal-lists">
                            {
                                Lists.map((list, i) =>
                                    <ModalTab key={i} {...list} AddProduct={addProduct} />
                                )
                            }
                        </ul>
                        <div className="p-2 mt-1">
                            <h5>{translateResourceString('/Wishlists/ViewAndAddModal/CreateListLabel')}</h5>
                            <NewListInput
                                Lists={Lists}
                                UpdateLists={UpdateLists}
                                AddProduct={addProduct}
                                CreateListEndpoint={CreateListEndpoint} />
                        </div>
                    </div>
                </div>
            </div>
        </ModalBody>
    ) : (
            <ModalBody>
                <div className="conainer-fluid"><div className="row"><div className="col-12 m-auto">
                    <a href={LoginLink} title="login">{translateResourceString("/Wishlists/AddToModal/PleaseLoginMessage")}</a></div></div></div>
            </ModalBody>
        )

    return (
        <Modal
            isOpen={ModalIsOpen}
            toggle={() => ToggleModal(false)}
            centered={true}
            className="modal-lg"
            contentClassName="list-modal"
            role="document">
            <ModalHeader toggle={() => ToggleModal(false)} wrapTag="div" tag="span" className="headline headline-sm">
                {UserIsAuthorized ? translateResourceString("/Wishlists/AddToModal/Header") : translateResourceString("/Wishlists/AddToModal/HeaderUnauthorized")}
            </ModalHeader>
            {modalBody}
        </Modal>
    );
};

AddToModal.propType = {
    Lists: PropTypes.array.isRequired,
    UpdateLists: PropTypes.func.isRequired,
    ModalIsOpen: PropTypes.bool.isRequired,
    ToggleModal: PropTypes.func.isRequired,
    AddItemEndpoint: PropTypes.string.isRequired,
    CreateListEndpoint: PropTypes.string.isRequired,
    UserIsAuthorized: PropTypes.bool.isRequired,
    LoginLink: PropTypes.string.isRequired
};

export default AddToModal;
